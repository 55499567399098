import React from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { Container } from '@mui/material'

import Layout from 'public/components/organisms/global/Layout'
import PageTitleHero, {
  PageTitleHeroProps,
} from 'public/components/organisms/hero/PageTitleHero'
import TextSection from 'public/components/organisms/sections/TextSection'
import Mandates from 'public/components/organisms/sections/Mandates'
import Section from '_core/components/atoms/layout/Section'
import MasonryGrid from '_core/components/organisms/masonry/MasonryGrid'
import NewsCard from 'public/components/molecules/cards/news/NewsCard'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'
import BlogPostScroll from 'public/components/organisms/sections/BlogPostScroll'

interface Props {
  heroProps: Omit<PageTitleHeroProps, 'smallHeading'>
  postTitle?: string
  introText?: string
  mandates: Queries.DatoCmsPageMandate[]
  news: Queries.DatoCmsIndustryNews[]
  posts: Queries.DatoCmsBlogPost[]
  seoTags: Queries.DatoCmsSeoMetaTags
  seoImage: IGatsbyImageData
}

const TermDetail = ({
  heroProps,
  introText,
  mandates,
  news,
  posts,
  seoTags,
  seoImage,
}: Props) => {
  const hasPosts = posts && posts.length > 3 ? true : false

  let metaDescription
  if (introText) {
    metaDescription =
      introText.replace(/(\r\n|\n|\r)/gm, '').substring(0, 160) + '...'
  } else if (mandates && mandates.length > 0) {
    let bulletString = ''
    mandates[0].node.bulletPoints.map((bullet: Queries.DatoCmsBulletPoint) => {
      bulletString = bulletString + bullet.bulletPoint + '. '
    })
    metaDescription = 'Latest mandate: ' + mandates[0].node.title + bulletString
    metaDescription =
      metaDescription.replace(/(\r\n|\n|\r)/gm, '').substring(0, 160) + '...'
  }

  return (
    <Layout
      metadata={{
        seoTags: seoTags,
        extraTitle: 'Executive Search',
        customHeadingSpacer: ' ',
        customDescription: metaDescription,
        customImage: {
          src: seoImage?.images?.fallback?.src,
          width: seoImage.width,
          height: seoImage.height,
        },
      }}
    >
      <PageTitleHero {...heroProps} smallHeading="Executive Search" />
      {introText && <TextSection leadText={introText} />}

      {mandates && mandates.length > 0 && (
        <Mandates
          mandates={mandates}
          heading="Latest Mandates"
          buttonSlug="mandates"
          buttonLabel="View All Mandates"
        />
      )}
      {hasPosts && (
        <BlogPostScroll posts={posts} heading={`Recent Blog Posts`} />
      )}
      {news && news.length > 4 && (
        <Section bgcolor={hasPosts ? 'lightGrey' : 'white'} vSpace>
          <Container vertical-padding-compact="true" maxWidth={false}>
            <SectionHeading heading={`In The News`} />
            <MasonryGrid className="news">
              {news.map((item) => (
                <NewsCard
                  key={`news-${item.node.originalId}`}
                  newsData={item.node}
                  whiteBg={hasPosts ? undefined : true}
                />
              ))}
            </MasonryGrid>
          </Container>
        </Section>
      )}
    </Layout>
  )
}

export default TermDetail
